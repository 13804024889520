import { useEffect } from "react";
import {
  SHOW_PAGE_IN_SIDE_BY_SIDE_EVENT,
  ShowPageInPageView,
} from "../../markdown/PageRefLink";

export const useListenToPageChangeEvent = (
  setPage: (pageIndex: number) => void,
  setSelectedComponentId: (componentId: string | null) => void,
) => {
  useEffect(() => {
    const handleEvent = (event: any) => {
      const { pageIndex, componentId } = event.detail as ShowPageInPageView;
      setPage(parseInt(pageIndex) - 1);
      setSelectedComponentId(componentId ? componentId : null);
    };

    window.addEventListener(SHOW_PAGE_IN_SIDE_BY_SIDE_EVENT, handleEvent);

    return () => {
      window.removeEventListener(SHOW_PAGE_IN_SIDE_BY_SIDE_EVENT, handleEvent);
    };
  }, [setPage, setSelectedComponentId]);
};
