import {
  Component,
  PlanAnnotation,
  PlanPage,
  Rect,
  XYPoint,
} from "../../model/blueprintServer";
import { CanvasAnnotation } from "./Overlay";

export const findFirstRectForPoint = (
  annotationsMap: {
    [index: string]: CanvasAnnotation;
  },
  point: XYPoint,
  pageNumber: number,
  scale: number,
) => {
  const annotations = Object.entries(annotationsMap).filter(
    ([ignore, annotation]) => annotation.pageNumber === pageNumber,
  );
  const { x, y } = scaleUpPoint(point, scale);
  let index = 0;
  for (const [key, annotation] of annotations) {
    const { rect } = annotation;

    if (
      x >= rect.start.x &&
      x <= rect.end.x &&
      y >= rect.start.y &&
      y <= rect.end.y
    ) {
      return { key, index, annotation };
    }
    index++;
  }

  return null;
};

export const getMeta = (url: string, cb: (img: HTMLImageElement) => void) => {
  const img = new Image();
  img.onload = () => cb(img);
  // img.onerror = (err) => cb(err);
  img.src = url;
};

export const scaleUpRect = (rect: Rect, scale: number): Rect => {
  return {
    start: scaleUpPoint(rect.start, scale),
    end: scaleUpPoint(rect.end, scale),
  };
};

export const scaleUpPoint = (point: XYPoint, scale: number): XYPoint => {
  const invertScale = 1 / scale;
  return { x: point.x * invertScale, y: point.y * invertScale };
};

export const PlanAnnotationToCanvasAnnotation = (
  planAnnotation: PlanAnnotation,
): CanvasAnnotation => ({
  isHovered: false,
  isSelected: false,
  id: planAnnotation.id,
  pageNumber: planAnnotation.pageNumber,
  rect: planAnnotation.rect,
  label: planAnnotation.label,
});

export const componentToCanvasAnnotation = (
  component: Component,
  pageNumber: number,
): CanvasAnnotation => ({
  isHovered: false,
  isSelected: false,
  id: component.componentId,
  pageNumber,
  rect: component.rect,
  label: "",
});

export const pageToCanvasAnnotations = (planPage: PlanPage) =>
  planPage.components.reduce(
    (acc, component) => {
      return {
        ...acc,
        [component.componentId]: componentToCanvasAnnotation(
          component,
          planPage.pageNumber,
        ),
      };
    },
    {} as { [key: string]: CanvasAnnotation },
  );
