import { DataState } from "./projectsAtom";
import { PlanQuestion } from "../model/blueprintServer";
import { atom } from "recoil";

export interface QuestionAtom {
  state: DataState;
  data: PlanQuestion[];
}

export const questionsAtom = atom<QuestionAtom>({
  key: "currentInqueryAtom",
  default: {
    state: DataState.NOT_LOADED,
    data: [],
  },
});
