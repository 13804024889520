import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { ComponentData } from "../../model/blueprintServer";
import { ModalContentComponent } from "../theme/useModal";
import { adminApi } from "../../api/adminApi";
import "./adminUpdateComponentData.scss";

export interface AdminUpdateComponentDataData {
  planId: string;
  pageNumber: number;
  componentIndex: number;
  componentData: ComponentData;
  imageURL: string;
}

const AdminUpdateComponentData = ({
  data,
  handleClose,
}: ModalContentComponent<AdminUpdateComponentDataData>) => {
  if (!data) {
    return null;
  }

  return <AdminUpdateComponentDataInner {...data} handleClose={handleClose} />;
};

interface AdminUpdateComponentDataInnerProps
  extends AdminUpdateComponentDataData {
  handleClose: () => void;
}

const AdminUpdateComponentDataInner = ({
  componentIndex,
  componentData,
  pageNumber,
  planId,
  handleClose,
  imageURL,
}: AdminUpdateComponentDataInnerProps) => {
  const [title, setTitle] = useState(componentData.title);
  const [description, setDescription] = useState(componentData.description);
  const [extractedData, setExtractedData] = useState(
    componentData.extractedData,
  );
  const [categories, setCategories] = useState(
    componentData.categories.join(", "),
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleUpdate = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    const updatedComponentData: ComponentData = {
      title,
      description,
      extractedData,
      categories: categories.split(",").map((cat) => cat.trim()),
    };

    try {
      await adminApi.updateComponentText(
        planId,
        pageNumber,
        componentIndex,
        updatedComponentData,
      );
      setSuccess(true);
    } catch (err) {
      setError("Failed to update component data");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-update-component-data">
      <div className="content-container">
        {/* Image Section */}
        <div className="image-container">
          <img
            src={imageURL}
            alt="Component Preview"
            className="component-image"
          />
        </div>

        {/* Text Fields Section */}
        <div className="text-container">
          <label>
            Title:
            <textarea
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </label>

          <label>
            Description (Markdown Preview Below):
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <div className="markdown-preview">
              <ReactMarkdown>{description}</ReactMarkdown>
            </div>
          </label>

          <label>
            Extracted Data (Markdown Preview Below):
            <textarea
              value={extractedData}
              onChange={(e) => setExtractedData(e.target.value)}
            />
            <div className="markdown-preview">
              <ReactMarkdown>{extractedData}</ReactMarkdown>
            </div>
          </label>

          <label>
            Categories (comma-separated):
            <textarea
              value={categories}
              onChange={(e) => setCategories(e.target.value)}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default AdminUpdateComponentData;
