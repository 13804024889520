import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  areaRect,
  CanvasAnnotation,
  clearRectPainter,
  Painter,
  runPainter,
} from "./Overlay";
import { pageImageSizeAtom } from "../../atom/pageImageSizeAtom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Size } from "../../model/math";
import { useDrag } from "./useDrag";
import {
  BlobStorage,
  PlanAnnotation,
  Rect,
  XYPoint,
} from "../../model/blueprintServer";
import { formatImageUrl } from "../../util/imageUtil";
import { throttle } from "lodash";
import { annotationForPainter } from "./overlayUtils";
import { findFirstRectForPoint, getMeta, scaleUpRect } from "./canvasUtil";
import "./canvas.scss";

export const useCanvas = (
  imageBlob: BlobStorage,
  pageNumber: number,
  annotations: { [key: string]: CanvasAnnotation },
  handleAddAnnotation: (pageNumber: number, rect: Rect) => void,
  handleSelectedAnnotationKey: (key: string | null) => void,
) => {
  const [size, setSize] = useState<Size | null>(null);
  const [currentRect, setCurrentRect] = useState<Rect | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const {
    scale,
    adjustedContainerHeight,
    adjustedContainerWidth,
    originalImageWidth,
    originalImageHeight,
  } = useRecoilValue(pageImageSizeAtom);
  const [currentHoverPoint, setCurrentHoverPoint] = useState<XYPoint>({
    x: -1,
    y: -1,
  });
  const [currentClickPoint, setCurrentClickPoint] = useState<XYPoint>({
    x: -1,
    y: -1,
  });

  const handleRectMove = useCallback(
    (rect: Rect, isNewRect: boolean) => {
      setCurrentRect(!isNewRect ? scaleUpRect(rect, scale) : null);
      if (isNewRect) {
        handleAddAnnotation(pageNumber, scaleUpRect(rect, scale));
      }
    },
    [setCurrentRect, scale, pageNumber, handleAddAnnotation],
  );
  const { handleMouseDown } = useDrag(handleRectMove);

  const setImageSize = useSetRecoilState(pageImageSizeAtom);
  const handleContainerChange = useCallback(() => {
    if (!canvasRef.current || !canvasRef.current?.parentElement) {
      return;
    }

    if (!size) {
      return;
    }

    const { width, height } = size;
    const containerWidth =
      canvasRef.current.parentElement.getBoundingClientRect().width;
    const scale = containerWidth > width ? 1 : containerWidth / width;
    const adjustedContainerWidth =
      containerWidth > width ? width : containerWidth;
    const adjustedContainerHeight = height * scale;
    const adjustedVideoHeight = Math.min(adjustedContainerHeight, height);
    const adjustedVideoWidth = Math.min(adjustedContainerWidth, width);
    setImageSize({
      containerWidth,
      scale,
      adjustedContainerWidth,
      adjustedContainerHeight,
      adjustedVideoWidth,
      adjustedVideoHeight,
      originalImageWidth: width,
      originalImageHeight: height,
    });
  }, [setImageSize, canvasRef, size]);

  useEffect(() => {
    handleContainerChange();
    window.addEventListener("resize", handleContainerChange, true);
    return () => {
      window.removeEventListener("resize", handleContainerChange);
    };
  }, [handleContainerChange]);

  const drawFrame = useCallback(
    (imageUrl: string, painters: Painter[]) => {
      let canvas = canvasRef.current;
      if (!canvas) {
        return;
      }

      const ctx = canvas.getContext("2d");
      if (!ctx) {
        return;
      }

      if (!size) {
        getMeta(imageUrl, (imgEle) => {
          setSize({ width: imgEle.naturalWidth, height: imgEle.naturalHeight });
        });
        return;
      }

      for (const painter of painters) {
        ctx.scale(scale, scale);
        runPainter(ctx, painter);
        ctx.setTransform(1, 0, 0, 1, 0, 0);
      }
    },
    [canvasRef, scale, size, setSize],
  );

  const findRect = useCallback(
    (point: XYPoint) => {
      return findFirstRectForPoint(annotations, point, pageNumber, scale);
    },
    [pageNumber, scale, annotations],
  );

  useEffect(() => {
    const handleMouseMove = throttle((e) => {
      const { offsetX, offsetY } = e;
      if (offsetY < 0 || offsetX < 0) {
        return;
      }
      // For optimization, set the hover rect here instead of the big useEffect and avoid re-render unless there is a
      // hovered rect.
      setCurrentHoverPoint({ x: offsetX, y: offsetY });
    }, 100);
    canvasRef.current?.addEventListener("mousemove", handleMouseMove);

    const ref = canvasRef.current;
    return () => {
      ref?.removeEventListener("mousemove", handleMouseMove);
    };
  }, [canvasRef, findRect]);

  useEffect(() => {
    const handleClick = throttle((e) => {
      const { offsetX, offsetY } = e;
      if (offsetY < 0 || offsetX < 0) {
        return;
      }

      const point = { x: offsetX, y: offsetY };
      setCurrentClickPoint(point);
      const clickOnAnnotation = findRect(point);
      if (clickOnAnnotation) {
        handleSelectedAnnotationKey(clickOnAnnotation.key);
      } else {
        handleSelectedAnnotationKey(null);
      }
    }, 100);
    canvasRef.current?.addEventListener("click", handleClick);

    const ref = canvasRef.current;
    return () => {
      ref?.removeEventListener("click", handleClick);
    };
  }, [canvasRef, findRect, handleSelectedAnnotationKey, currentClickPoint]);

  useEffect(() => {
    const painters = [
      clearRectPainter(originalImageWidth, originalImageHeight),
    ];

    if (currentRect) {
      const annotation: PlanAnnotation = {
        rect: currentRect,
        label: "",
        id: "",
        planQaId: "",
        pageNumber: pageNumber,
        image: { bucket: "", id: "", md5: "" },
        ready: true,
        failed: false,
      };
      painters.push(areaRect([annotationForPainter(annotation)]));
    }

    const hoverOnAnnotation = findRect(currentHoverPoint);
    let hoverRectIndex = -1;
    if (hoverOnAnnotation) {
      hoverRectIndex = hoverOnAnnotation.index;
    }

    const clickOnAnnotation = findRect(currentClickPoint);
    let clickRectIndex = -1;
    if (clickOnAnnotation) {
      clickRectIndex = clickOnAnnotation.index;
    }

    const rects = Object.values(annotations)
      .filter((a) => a.pageNumber === pageNumber)
      .map((canvasAnnotation, index) => ({
        ...canvasAnnotation,
        isHovered: hoverRectIndex === index,
        isSelected: clickRectIndex === index,
      }));

    painters.push(areaRect(rects));
    drawFrame(formatImageUrl(imageBlob), painters);
  }, [
    scale,
    drawFrame,
    originalImageHeight,
    originalImageWidth,
    currentRect,
    imageBlob,
    pageNumber,
    currentHoverPoint,
    currentClickPoint,
    findRect,
    annotations,
  ]);

  const Canvas = useMemo(
    () => (
      <canvas
        ref={canvasRef}
        width={adjustedContainerWidth}
        height={adjustedContainerHeight}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        id="canvas"
      ></canvas>
    ),
    [
      canvasRef,
      adjustedContainerHeight,
      adjustedContainerWidth,
      handleMouseDown,
    ],
  );
  return { Canvas, canvasRef, drawFrame };
};
