import { CityName } from "../../model/blueprintServer";

const linksRegex = /\[(.*?)\]\((.*?)\)/g;

// This interface is matched by a python AI call model, we can't change that alone.
export interface BuildingCodeCitation {
  type: string;
  section: string;
  year: number;
  cityName: CityName;
}

export interface PageReferenceCitation {
  type: string;
  pageIndex: number;
  componentId?: string | undefined;
}
const getBuildingCodeSectionCitation = (text: string, citation: string) =>
  `<span data-building-code-ref='${citation}'>${text}</span>`;

const getPageReferenceCitation = (
  page: string,
  componentId: string | undefined,
) =>
  `<span data-page-ref='${page}' data-component-id='${componentId}'>${page}</span>`;

const replaceAt = (
  text: string,
  indexPosition: number,
  replaceLength: number,
  chr: string,
) => {
  if (indexPosition > text.length - 1) return text;
  let out =
    text.substring(0, indexPosition) +
    text.substring(indexPosition + replaceLength);
  out = out.slice(0, indexPosition) + chr + out.slice(indexPosition);
  return out;
};

export interface TextAndCitations {
  text: string;
  citations: BuildingCodeCitation[];
}
const matchText = (text: string) => new RegExp(linksRegex).exec(text);

export const detectSpecialLinks = (text: string): TextAndCitations => {
  let citations: BuildingCodeCitation[] = [];
  let match;
  while ((match = new RegExp(linksRegex).exec(text)) != null) {
    try {
      const { text: newText, citations: newCitations } = replaceTextWithFinds(
        text,
        match,
      );
      citations = [...citations, ...newCitations];
      text = newText;
    } catch (e) {
      console.warn("Failed to parse potential markdown: " + match);
      text = replaceTextWithFailedToFind(text, match);
    }
  }

  return { text, citations };
};

export const replaceTextWithFinds = (text: string, match: RegExpMatchArray) => {
  const citations: BuildingCodeCitation[] = [];
  const buildingCodeCitation = JSON.parse(match[2]) as BuildingCodeCitation;
  if (buildingCodeCitation && buildingCodeCitation?.type === "section") {
    text = replaceAt(
      text,
      match.index || 0,
      match[0].length,
      getBuildingCodeSectionCitation(match[1], match[2]),
    );
    citations.push(buildingCodeCitation);
  }

  const pageReferenceCitation = JSON.parse(match[2]) as PageReferenceCitation;
  if (
    pageReferenceCitation &&
    pageReferenceCitation?.type === "pageReference"
  ) {
    text = replaceAt(
      text,
      match.index || 0,
      match[0].length,
      getPageReferenceCitation(match[1], pageReferenceCitation.componentId),
    );
  }

  return { text, citations };
};

export const replaceTextWithFailedToFind = (
  text: string,
  match: RegExpMatchArray,
) => {
  return replaceAt(text, match.index || 0, match[0].length, "[Error in link]");
};
