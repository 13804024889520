import React, { useEffect, useRef } from "react";
import { useActivePlan } from "../projects/hook/useActivePlan";
import { formatImageUrl } from "../../util/imageUtil";
import "./pagesIndex.scss";
import classNames from "classnames";

interface PagesIndexProps {
  setPageIndex: (page: number) => void;
  currentPageIndex: number;
}

export const PagesIndex = ({
  setPageIndex,
  currentPageIndex,
}: PagesIndexProps) => {
  const { activePlan } = useActivePlan();
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const currentRef = pageRefs.current[currentPageIndex];
    if (currentRef) {
      currentRef.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [currentPageIndex]);

  if (!activePlan) {
    return null;
  }

  const pages = activePlan.pages.map((page, index) => (
    <div
      key={page.pageNumber}
      className={classNames("item flex", {
        selected: currentPageIndex === page.pageNumber,
      })}
      ref={(el) => (pageRefs.current[index] = el)}
    >
      <img
        src={formatImageUrl(page.thumbnailBlob)}
        onClick={() => setPageIndex(page.pageNumber)}
        key={page.id}
      />
      <span>{page.pageNumber + 1}</span>
    </div>
  ));
  return <div className="pages-index flex">{pages}</div>;
};
