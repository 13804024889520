import React, { useMemo, useRef } from "react";
import { marked } from "marked";
import { BuildingCodeCitation, detectSpecialLinks } from "./parseCitationUtil";
import parse, { DOMNode, Element, Text } from "html-react-parser";
import { useFetchBuildingCode } from "../projects/hook/useFetchBuildingCode";
import { BuildingCodeRefLink } from "./BuildingCodeRefLink";
import { PageRefLink } from "./PageRefLink";

interface MountHtmlProps {
  markdown: string;
}

const hasDataCitation = (element: Element) =>
  !!element.attributes.find(
    (a) => a.name === "data-building-code-ref" || a.name === "data-page-ref",
  );

const replaceFunction = (domNode: DOMNode) => {
  const el = domNode as Element;
  if (el.attribs && el.tagName === "span") {
    const buildingCodeAttr = el.attributes.find(
      (a) => a.name === "data-building-code-ref",
    );
    const pageRefRef = el.attributes.find((a) => a.name === "data-page-ref");
    if (buildingCodeAttr) {
      const citation = JSON.parse(
        buildingCodeAttr.value,
      ) as BuildingCodeCitation;
      return (
        <BuildingCodeRefLink
          citation={citation}
          text={(el.children[0] as Text).data}
        />
      );
    } else if (pageRefRef) {
      const optionalComponentId = el.attributes.find(
        (a) => a.name === "data-component-id",
      );
      return (
        <PageRefLink
          page={(el.children[0] as Text).data}
          componentId={optionalComponentId?.value}
        />
      );
    } else if (hasDataCitation(el)) {
      return null;
    }
  }
  return domNode;
};

export const MarkdownCitation = ({ markdown }: MountHtmlProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const fetchBuildingCode = useFetchBuildingCode();

  const content = useMemo(() => {
    const { text, citations } = detectSpecialLinks(markdown);
    // TODO: send with each section the city and year. Now we assume they are from the same city+year.
    if (citations.length !== 0) {
      const cityName = citations[0].cityName;
      const year = citations[0].year;
      const sections = citations.map((c) => c.section);
      fetchBuildingCode(cityName, year, sections);
    }

    if (!text) {
      return null;
    }

    const markdownHtml = marked.parse(text) as string;
    return parse(markdownHtml, {
      replace: replaceFunction,
    });
  }, [markdown, fetchBuildingCode]);

  return <div ref={ref}>{content}</div>;
};
