import { PagesIndex } from "../../planReport/PagesIndex";
import { PageViewAndCanvas } from "../../sideBySide/PageViewAndCanvas";
import React, { useEffect, useMemo, useState } from "react";
import { useActivePlan } from "../hook/useActivePlan";
import "./planViewer.scss";
import { useListenToPageChangeEvent } from "./useListenToPageChangeEvent";
import { SHOW_PAGE_IN_SIDE_BY_SIDE_EVENT } from "../../markdown/PageRefLink";
import { planApi } from "../../../api/planApi";
import { PlanModel } from "../../../model/blueprintServer";
import { CanvasAnnotation } from "../../canvas/Overlay";
import {
  componentToCanvasAnnotation,
  pageToCanvasAnnotations,
} from "../../canvas/canvasUtil";

export const PlanViewer = () => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const { activePlan } = useActivePlan();
  const [fullPlan, setFullPlan] = useState<PlanModel | null>(null); // Add state for fullPlan
  const [selectedComponentId, setSelectedComponentId] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (activePlan) {
      planApi.getFullPlan(activePlan.id).then(setFullPlan);
    }
  }, [activePlan]);

  const setPageIndex = (pageIndex: number) => {
    if (!activePlan) {
      return;
    }

    if (
      pageIndex > -1 &&
      pageIndex < activePlan.pages.length &&
      pageIndex !== currentPageIndex
    ) {
      setCurrentPageIndex(pageIndex);
      setIsLoadingImage(true);
    }
  };
  useListenToPageChangeEvent(setPageIndex, setSelectedComponentId);

  const pageComponents = useMemo((): { [key: string]: CanvasAnnotation } => {
    if (!fullPlan || !selectedComponentId) {
      return {};
    }

    const page = fullPlan.pages[currentPageIndex];
    const component = page.components.find(
      (c) => c.componentId === selectedComponentId,
    );
    if (component) {
      return {
        [component.componentId]: componentToCanvasAnnotation(
          component,
          page.pageNumber,
        ),
      };
    }
    return {};
  }, [fullPlan, currentPageIndex, selectedComponentId]);

  if (!activePlan) {
    return null;
  }

  return (
    <div className="plan-viewer flex">
      <PagesIndex
        setPageIndex={setPageIndex}
        currentPageIndex={currentPageIndex}
      />
      <PageViewAndCanvas
        page={activePlan.pages[currentPageIndex]}
        canvasAnnotations={pageComponents}
        handleFinishLoadImage={() => setIsLoadingImage(false)}
        isLoadingImage={isLoadingImage}
      />
    </div>
  );
};
