import React, { useMemo, useState } from "react";
import { MarkdownCitation } from "../markdown/MarkdownCitation";
import classNames from "classnames";
import {
  getReportResultClass,
  getReportResultClassForStr,
  getReportResultText,
} from "./reportUtil";
import { SectionComplianceReport } from "../../model/blueprintServer";
import { Button } from "../theme/Button";
import { codeSymbol } from "../../util/codeFormatUtil";

interface SectionCardProps {
  item: SectionComplianceReport;
}

export const SectionComplianceReportCard = ({ item }: SectionCardProps) => {
  const [showContent, setShowContent] = useState(false);

  const content = useMemo(() => {
    if (!showContent) {
      return null;
    }

    return (
      <div className="content">
        <div className="sub-title">Code ({item.buildingCode.year})</div>
        <div className="code">
          <MarkdownCitation markdown={item.buildingCode.text} />
        </div>
        <div className="sub-title">Analysis</div>
        <div className="analysis">
          {item.reasoning.map((reason, index) => (
            <div className="item">
              <h4>
                Reason {index + 1}{" "}
                <span
                  className={classNames(
                    getReportResultClassForStr(reason.evaluation),
                    "analysis-compliance",
                  )}
                >
                  {getReportResultText(reason.evaluation)}
                </span>
              </h4>
              <MarkdownCitation markdown={reason.analysis} key={index} />
            </div>
          ))}
        </div>
      </div>
    );
  }, [item, showContent]);

  return (
    <div className={classNames("card", { expended: showContent })}>
      <div
        className="flex card-header"
        onClick={() => setShowContent((old) => !old)}
      >
        <div
          className={classNames(
            "title",
            getReportResultClass(item.finalAssessment),
          )}
        >
          {codeSymbol(item.buildingCode)} {item.buildingCode.fullSectionPath} @{" "}
          {item.buildingCode.year}
        </div>
        <div className="chapter-title">{item.buildingCode.title}</div>
      </div>
      {content}
    </div>
  );
};
