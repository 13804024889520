import React, { useMemo, useState } from "react";
import { PlanPage } from "../../model/blueprintServer";
import { formatImageUrl } from "../../util/imageUtil";
import "../planReport/pageView.scss";
import { AppLogoLoader } from "../other/AppLogoLoader";
import { useCanvas } from "../canvas/useCanvas";
import { useRecoilValue } from "recoil";
import { pageImageSizeAtom } from "../../atom/pageImageSizeAtom";
import { CanvasAnnotation } from "../canvas/Overlay";

interface PageViewProps {
  page: PlanPage;
  handleFinishLoadImage: () => void;
  isLoadingImage: boolean;
  canvasAnnotations: { [key: string]: CanvasAnnotation };
}

export const PageViewAndCanvas = ({
  page,
  handleFinishLoadImage,
  isLoadingImage,
  canvasAnnotations,
}: PageViewProps) => {
  const { Canvas } = useCanvas(
    page.pageImageBlob,
    page.pageNumber,
    canvasAnnotations,
    () => {},
    () => {},
  );
  const { adjustedContainerWidth, adjustedContainerHeight } =
    useRecoilValue(pageImageSizeAtom);

  const imageLoader = useMemo(
    () =>
      isLoadingImage && (
        <div
          className="loader"
          style={{
            width: adjustedContainerWidth,
            height: adjustedContainerHeight,
          }}
        >
          <AppLogoLoader />
        </div>
      ),
    [isLoadingImage, adjustedContainerHeight, adjustedContainerWidth],
  );
  return (
    <div className="page-view-and-canvas">
      <div className="canvas-wrapper">
        <div className="canvas">
          {imageLoader}
          <img
            className="under-image"
            src={formatImageUrl(page.pageImageBlob)}
            onLoad={handleFinishLoadImage}
            style={{
              width: adjustedContainerWidth,
              height: adjustedContainerHeight,
            }}
          />
          {Canvas}
        </div>
      </div>
    </div>
  );
};
