import React from "react";
import "./pageRefLink.scss";
import { RiFocus3Line } from "react-icons/ri";

export const SHOW_PAGE_IN_SIDE_BY_SIDE_EVENT = "show-page-in-side-by-side";

export interface ShowPageInPageView {
  pageIndex: string;
  componentId?: string;
}

interface PageRefLink {
  page: string;
  componentId?: string | undefined;
}

export const PageRefLink = ({ page, componentId }: PageRefLink) => {
  const showPage = () =>
    window.dispatchEvent(
      new CustomEvent(SHOW_PAGE_IN_SIDE_BY_SIDE_EVENT, {
        detail: {
          pageIndex: page,
          componentId,
        } as ShowPageInPageView,
      }),
    );
  return (
    <span className="page-ref-link" onClick={showPage}>
      page {page}
      <RiFocus3Line />
    </span>
  );
};
