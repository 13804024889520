import { useCallback, useEffect } from "react";
import {
  DataLoadingStates,
  PlanQuestion,
} from "../../../model/blueprintServer";
import { questionApi } from "../../../api/questionApi";
import { useRecoilState } from "recoil";
import { questionsAtom } from "../../../atom/questionAtom";

export const usePollPlanQuestionStatus = (planQuestionId: string) => {
  const [questions, setQuestions] = useRecoilState(questionsAtom);

  const finishLoading = useCallback(() => {
    return (
      questions.data.find(({ id }) => id === planQuestionId)?.status ===
      DataLoadingStates.COMPLETED
    );
  }, [questions, planQuestionId]);

  const maybeUpdateReports = useCallback(() => {
    if (finishLoading()) {
      return;
    }

    questionApi.getQuestion(planQuestionId).then((newQuestion) => {
      // Replace the updated question data.
      setQuestions((old) => {
        const { data } = old;
        const newData = data.map((oldQuestion) => {
          if (oldQuestion.id === planQuestionId) {
            return newQuestion;
          }
          return oldQuestion;
        });
        return { ...old, data: newData };
      });
    });
  }, [finishLoading, setQuestions, planQuestionId]);

  useEffect(() => {
    const interval = setInterval(() => {
      maybeUpdateReports();
      if (finishLoading()) {
        clearInterval(interval);
      }
    }, 10 * 1000);

    return () => clearInterval(interval);
  }, [maybeUpdateReports, finishLoading]);
};
